import { useFormik } from "formik";
import { SpinnerCircularFixed } from "spinners-react";
import useModal from "Hooks/useModal";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../../images/arrowdown.svg";
import { ReactComponent as ArrowUp } from "../../../../images/arrowup.svg";
import { ReactComponent as Info } from "../../../../images/info.svg";
import { Links, ModalV2, PropertyForm } from "v2/components";
import Button from "v2/components/Form/Button/Button";
import Map from "v2/components/Map/Map";
import styles from "./PropertyInfo.module.css";
import { propertyAnalyticsPath } from "./PropertyInfoConstants";
import { updatePropertyDetails } from "Apis";
import Typography from "v2/components/Typography/Typography";
import { useWindowDimensions } from "utils";
import Table from "v2/components/ValuationTable/ValuationTable";
import {
  usePropertyAllowedActions,
  usePropertyAnalyticsActionsContext,
  usePropertyDetails,
  usePropertyGuidelines,
  usePropertySponsorContext,
  usePropertyUpdate,
} from "context/propertyContext/PropertyContext";
import {
  Prompt,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import MapGuideModal from "./MapGuideModal/MapModalGuide";
import SendEmailModal from "./SendEmailModal/SendEmailModal";
import { useUserData } from "context/environmentContext/EnvironmentContext";
import { mixPanel, pageVisited } from "utils";
import * as yup from "yup";

const PropertyInfo = (props) => {
  const visited = pageVisited();
  const { width } = useWindowDimensions();
  const [showForm, setShowForm] = useState(!visited);
  const propertyDetails = usePropertyDetails();
  const userDetails = useUserData();
  const { updatePropertyInfo } = usePropertyUpdate();
  const guidelines = usePropertyGuidelines();
  const { getAnalytics } = usePropertyAnalyticsActionsContext();
  const { readOnly, canViewPremiumData } = usePropertyAllowedActions();
  const { openSponsorModal } = usePropertySponsorContext();
  const { onSelectSponsor } = usePropertySponsorContext();
  const { url } = useRouteMatch();
  const formRef = useRef(null);

  const schemesExist = propertyDetails.schemes;

  const [isMapModalOpen, openMapModal, closeMapModal, leadMapModalData] =
    useModal();
  const [isEmailModalOpen, openEmailModal, closeEmailModal, emailModalData] =
    useModal();
  const [confirmPin, setConfirmPin] = useState(false);
  const [sendingPin, setSendingPin] = useState(false);
  const [cPError, setCPError] = useState("");
  const [CPV, setCPV] = useState(false);

  const PropertyFormValidationSchema = yup.object().shape({
    propertyType: yup
      .mixed()
      .oneOf(["Flat", "House", "Building"])
      .required("Required"),
    numberOfStoreys: yup.number().required("Required"),
    knowWhoLiveInProperty: yup.mixed().oneOf(["Yes", "No"]),
  });

  // Live data
  let liveData = propertyDetails?.schemes?.liveSchemes || [];
  liveData.forEach((s) => {
    s.startDate = s.startDate
      ? s.startDate.split("-").reverse().join("/")
      : null;
    s.endDate = s.endDate ? s.endDate.split("-").reverse().join("/") : null;
    Object.assign(s, {
      minTenants: s.rule.minTenants,
      minHouseholds: s.rule.minHouseholds,
      description: s.rule.description,
    });
  });
  // Upcoming Data
  let upcomingData = propertyDetails?.schemes?.upcomingSchemes || [];
  upcomingData.forEach((s) => {
    s.startDate = s.startDate
      ? s.startDate.split("-").reverse().join("/")
      : null;
    s.endDate = s.endDate ? s.endDate.split("-").reverse().join("/") : null;
    Object.assign(s, {
      minTenants: s.rule.minTenants,
      minHouseholds: s.rule.minHouseholds,
      description: s.rule.description,
    });
  });

  // Consultations Data
  let consultationData = propertyDetails?.schemes?.consultations || [];
  consultationData.forEach((s) => {
    s.startDate = s.startDate
      ? s.startDate.split("-").reverse().join("/")
      : null;
    s.endDate = s.endDate ? s.endDate.split("-").reverse().join("/") : null;
    Object.assign(s, {
      minTenants: s.rule.minTenants,
      minHouseholds: s.rule.minHouseholds,
      description: s.rule.description,
    });
  });

  const tableHeaders = {
    columnNames: [
      { Header: "Licence Type", accessor: "type" },
      { Header: "Min Tenants", accessor: "minTenants" },
      { Header: "Min Households", accessor: "minHouseholds" },
      { Header: "Start Date", accessor: "startDate" },
      { Header: "End Date", accessor: "endDate" },
    ],
  };

  const getRecentTenancy = (tenancies) => {
    if (tenancies.length === 0)
      // If no tenancies return false (Don't display anything)
      return false;

    if (tenancies.length === 1)
      // If there is only one tenancy, return that
      return tenancies[0];

    // If there are more than 1 tenancies, additional checks needed

    // Firstly get tenancy with latest startDate
    const latestStartDate = tenancies.reduce((a, b) => {
      return new Date(a.tenancyStartDate) > new Date(b.tenancyStartDate)
        ? a
        : b;
    });

    // Save new list of tenancies with the latest start date
    const latestStartDateTenancies = tenancies.filter(
      (t) => t.tenancyStartDate === latestStartDate.tenancyStartDate
    );

    // If there is only one tenancy, return that tenancy
    if (latestStartDateTenancies.length === 1) return latestStartDate;

    // If there was more than one, next check to see most recent "updatedAt" date
    const latestUpdated = latestStartDateTenancies.reduce((a, b) => {
      return new Date(a.updatedAt) > new Date(b.updatedAt) ? a : b;
    });

    // Save new list of all tenancies with the same and most recent "updatedAt" date
    const latestUpdatedTenancies = latestStartDateTenancies.filter(
      (t) => t.updatedAt === latestUpdated.updatedAt
    );

    // If there is only one tenancy, return it
    if (latestUpdatedTenancies.length == 1) return latestUpdated;

    // If there was more than one, next check to see most recent "createdAt" date
    const latestCreated = latestUpdatedTenancies.reduce((a, b) => {
      return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
    });

    // Return latest created
    return latestCreated;
  };

  const hasTenancy =
    propertyDetails.tenancies &&
    propertyDetails.tenancies.filter((t) => t.status === "current").length > 0;

  const tenancy = hasTenancy
    ? getRecentTenancy(
        propertyDetails.tenancies.filter((t) => t.status === "current")
      )
    : null;

  const tenancyId = hasTenancy ? tenancy.tenancyId : null;

  const formik = useFormik({
    initialValues: {
      hasTenancies: hasTenancy,
      tenancyId,
      propertyUuid: propertyDetails.uuid,
      propertyType: propertyDetails.propertyType || "",
      numberOfStoreys: propertyDetails.numberOfStoreys || 1,
      usageIntention: propertyDetails.usageIntention || "investmentProperty",
      roomsOrUnits: propertyDetails.roomsOrUnits || null,

      numberOfHouseholds: hasTenancy
        ? tenancy.numberOfHouseholds
        : propertyDetails.numberOfHouseholds || null,

      numberOfTenants: hasTenancy
        ? tenancy.numberOfTenants
        : propertyDetails.numberOfTenants || null,

      tenancyType: hasTenancy
        ? tenancy.tenancyType
        : propertyDetails.tenancyType || "Shared Tenancy (AST)",

      tenancyStartDate: hasTenancy
        ? (tenancy.tenancyStartDate
            ? new Date(tenancy.tenancyStartDate)
            : new Date(Date.now())
          )
            .toISOString()
            .split("T")[0]
        : (propertyDetails.tenancyStartDate
            ? new Date(propertyDetails.tenancyStartDate)
            : new Date(Date.now())
          )
            .toISOString()
            .split("T")[0],

      tenancyEndDate: hasTenancy
        ? tenancy.tenancyEndDate
          ? new Date(tenancy.tenancyEndDate).toISOString().split("T")[0]
          : null
        : propertyDetails.tenancyEndDate
        ? new Date(propertyDetails.tenancyEndDate).toISOString().split("T")[0]
        : null,

      knowWhoLiveInProperty: propertyDetails.knowWhoLiveInProperty || "No",
      propertyCategory: propertyDetails.propertyCategory || "Unknown",
      commercialPremises: propertyDetails.commercialPremises || "Unknown",
      buildingConstructionType:
        propertyDetails.buildingConstructionType || "Unknown",
    },
    onSubmit: (event) => {
      onSubmitCallback(event);
      mixPanel.track("Submit property info");
    },
    validationSchema: PropertyFormValidationSchema,
  });

  const onSaveCallback = async (data) => {
    await updatePropertyInfo(data);
  };

  const onSubmitCallback = useCallback(
    async (data) => {
      await updatePropertyInfo(data);
      props.onNext();
    },
    [updatePropertyInfo, props]
  );

  const [loading, setLoading] = useState(false);

  const onRequestDataAnalytics = (propertyType, analyticName) => {
    if (!propertyType) {
      formRef.current.onMenuOpen();
      return;
    }
    setLoading(true);
    getAnalytics(analyticName);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  useEffect(() => {
    props.setActionRequired(propertyDetails.actionRequired);
  }, [propertyDetails.actionRequired]);

  useEffect(() => {
    setLoading(false);
    setConfirmPin(
      !!props.actionRequired?.some(
        (a) => a.value === "missing uprn" || a.value === "confirm pin location"
      )
    );
  }, [props.actionRequired]);

  // useEffect(() => {
  //   const searchParam = new URLSearchParams(window.location.search);
  //   const pif = searchParam.get("pif") === "true";
  //   setShowForm(pif);
  // }, [window.location.search]);

  const toggle = () => {
    if (showForm) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  };

  const viewButton = (analytics) => {
    const bedrooms = propertyDetails.roomsOrUnits;
    const localHousingBedrooms = bedrooms > 4 ? 4 : bedrooms;
    const lettingBedrooms = bedrooms > 5 ? 5 : bedrooms;
    let filteredAnalytics;
    if (analytics.hasOwnProperty("uprn-title")) {
      filteredAnalytics = Object.values(analytics);
    } else {
      filteredAnalytics = [
        analytics["rents"].filter(
          (r) => r.parameters.bedrooms == lettingBedrooms
        ),
        analytics["yields"].filter(
          (y) => y.parameters.bedrooms == lettingBedrooms
        ),
        analytics["lha-rate"].filter(
          (l) => l.parameters.bedrooms == localHousingBedrooms
        ),
        analytics["rentalizer-summary"].filter(
          (y) => y.data.property_details?.bedrooms == bedrooms
        ),
        analytics["rents-hmo"],
      ];
    }
    return filteredAnalytics.some((a) => a.length === 0);
  };

  const analyticsDataObjects = propertyAnalyticsPath.map((pathObject) => {
    const dataTypes = {};
    pathObject.dataTypes.forEach((dataType) => {
      dataTypes[dataType] = propertyDetails?.analytics
        .filter((analytic) => analytic.dataType === dataType)
        .map(({ data, parameters }) => ({ data: data, parameters }));
    });
    return dataTypes;
  });

  const onNewCoordinate = ({ lat, lng }) => {
    updatePropertyDetails(propertyDetails.uuid, {
      userLongitude: lng,
      userLatitude: lat,
    });
  };

  const cPErrorMessage = async (message) => {
    setCPError(message);
    setCPV(true);
    await new Promise((resolve, reject) => {
      setTimeout(() => resolve(), 5000);
    });
    setCPV(false);
    setCPError(null);
  };

  const confirmPinLocation = async () => {
    setSendingPin(true);
    let updatedProp;
    try {
      updatedProp = await updatePropertyDetails(propertyDetails.uuid, {
        userLongitude: parseFloat(propertyDetails.longitude),
        userLatitude: parseFloat(propertyDetails.latitude),
      });
      setSendingPin(false);
    } catch (error) {
      setSendingPin(false);
      cPErrorMessage(error?.message);
    } finally {
      if (updatedProp) {
        props.setActionRequired(updatedProp.data.actionRequired);
      }
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerLeftRight}>
          <div className={styles.right}>
            {width <= 768 && (
              <div className={styles.continueButtonContainer}>
                <Button
                  actionName="Continue to Journey ➔"
                  onClick={() => onSubmitCallback(formik)}
                />
              </div>
            )}
            <div className={styles.formContainer}>
              <div className={styles.formTop} onClick={toggle}>
                <Typography as="h3">Property Information Form</Typography>
                {showForm ? <ArrowUp /> : <ArrowDown />}
              </div>
              {showForm && (
                <PropertyForm
                  isReadOnly={readOnly}
                  formRef={formRef}
                  onNext={props.onNext}
                  formik={formik}
                  onSubmit={() => onSubmitCallback(formik)}
                  onSave={() => onSaveCallback(formik)}
                  propertyId={props.propertyId}
                  propertyUuid={propertyDetails.uuid}
                  initialValues={propertyDetails}
                  propertyDetails={propertyDetails}
                />
              )}
            </div>
            <div className={styles.topMenu}>
              {propertyAnalyticsPath.map((pathObject, index) => (
                <Links
                  as="navLink"
                  path={`${url}${pathObject.path}`}
                  key={index}
                >
                  {pathObject.name}
                </Links>
              ))}
            </div>
            <div className={styles.rentalAndSalesContainer}>
              <Prompt
                message={(location) => {
                  const isJourneyOrSupport =
                    location.pathname.includes("journey") ||
                    location.pathname.includes("support");
                  if (
                    isJourneyOrSupport &&
                    !propertyDetails.propertyType &&
                    !!formRef.current
                  ) {
                    formRef.current.onMenuOpen();
                    return false;
                  }
                  return true;
                }}
              />
              <Switch>
                {propertyAnalyticsPath.map((pathObject, index) => (
                  <Route exact path={`${url}${pathObject.path}`} key={index}>
                    <pathObject.Component
                      propertyDetails={propertyDetails}
                      analytics={analyticsDataObjects[index]}
                      canViewPremiumData={canViewPremiumData}
                      openSponsorModal={openSponsorModal}
                    />
                    {viewButton(analyticsDataObjects[index]) && (
                      <>
                        {!loading && (
                          <Button
                            actionName={loading ? "" : pathObject.dataButton}
                            onClick={() => {
                              onRequestDataAnalytics(
                                propertyDetails.propertyType,
                                pathObject.analyticName
                              );
                            }}
                          />
                        )}
                        {loading && <SpinnerCircularFixed />}
                      </>
                    )}
                    {pathObject.analyticName === "compareRents" ? (
                      <>
                        <div className={styles.hDivider}></div>
                        <span className={styles.disclaimer}>
                          <Typography as="propertyInfoAnalyticsData">
                            Rental rates are based on the data available in the
                            local market and may not always be perfect. If your
                            property is in good condition, or has key rental
                            features, you may get a more rental income for your
                            property. Speak to a member of the team for expert
                            pricing and support.
                          </Typography>
                          <div
                            className={styles.contactButton}
                            onClick={openEmailModal}
                          >
                            <a>Get in contact</a>
                          </div>
                          {isEmailModalOpen && (
                            <ModalV2 onClose={closeEmailModal}>
                              <SendEmailModal
                                onClose={closeEmailModal}
                                user={userDetails}
                                property={propertyDetails}
                              />
                            </ModalV2>
                          )}
                        </span>
                      </>
                    ) : null}
                  </Route>
                ))}
                <Redirect to={`${url}${propertyAnalyticsPath[0].path}`} />
              </Switch>
            </div>
          </div>
          <div className={styles.left}>
            <div className={styles.map}>
              {width <= 768 && (
                <div className={styles.mapWarning}>
                  <br />
                  Please use a computer when trying to edit the map pin location
                </div>
              )}

              {width > 768 && (
                <div className={styles.continueButtonContainer}>
                  <Button
                    actionName="Continue to Journey ➔"
                    onClick={() => onSubmitCallback(formik.values)}
                  />
                </div>
              )}

              <Map
                onNewCoordinate={onNewCoordinate}
                readOnly={propertyDetails.CRMUniqueIdentifier ? true : false}
                latitude={propertyDetails.preferredCoordinates.latitude}
                longitude={propertyDetails.preferredCoordinates.longitude}
              />
              <div className={styles.mapGuide} onClick={openMapModal}>
                {propertyDetails.CRMUniqueIdentifier
                  ? "If the pin is not directly on the property please adjust it using your CRM software."
                  : "If the pin is not directly on the property you can adjust it by double clicking pin."}
                <Info />
              </div>
            </div>

            <div className={styles.mapGuideButton}>
              <div
                className={styles.cPError}
                style={{ display: CPV ? "flex" : "none" }}
              >
                Error: {cPError}
              </div>
              {confirmPin && (
                <div
                  className={
                    sendingPin
                      ? `${styles.confirmButton} ${styles.loading}`
                      : styles.confirmButton
                  }
                  onClick={confirmPinLocation}
                >
                  {sendingPin ? (
                    <SpinnerCircularFixed
                      color="#F2C94C"
                      secondaryColor="#ddd"
                      size="2rem"
                    />
                  ) : (
                    "Confirm Pin Location"
                  )}
                </div>
              )}
            </div>
            {isMapModalOpen && (
              <ModalV2 onClose={closeMapModal}>
                <MapGuideModal onClose={closeMapModal} />
              </ModalV2>
            )}
            {/* {!!guidelines && (
              <LicencesList
                loading={!guidelines}
                propertyDetails={propertyDetails}
                formRef={formRef}
                licences={guidelines}
                canViewPremiumData={true}
                openSponsorModal={openSponsorModal}
                onSelectSponsor={onSelectSponsor}
              />
            )} */}
          </div>
        </div>
        {/* {width > 768 && (
          <div className={styles.journeyButton}>
            <Button
              actionName="Continue to Journey ➔"
              onClick={props.onNext}
            />
          </div>
        )} */}
      </div>
      <div className={styles.containerBottom}>
        {/* // Begin hacky table */}
        {schemesExist && (
          <div className={styles.tableContainer}>
            <br />
            <br />
            <u>
              <Typography as="h2">Local Compliance Schemes</Typography>
            </u>
            <div className={styles.title}>
              <Typography as="h3">Live Schemes</Typography>
              <span className={styles.titleDesc}>
                (Based on property location only)
              </span>
            </div>
            <Table headers={tableHeaders} data={liveData} />
            <br />
            <br />
            <div className={styles.title}>
              <Typography as="h3">Upcoming Schemes</Typography>
              <span className={styles.titleDesc}>
                (Based on property location only)
              </span>
            </div>
            <Table headers={tableHeaders} data={upcomingData} />
            <br />
            <br />
            <div className={styles.title}>
              <Typography as="h3">Consultations</Typography>

              <span className={styles.titleDesc}>
                (This is based on the council information, not the property or
                its use case at the property.)
              </span>
            </div>
            <Table headers={tableHeaders} data={consultationData} />
            <br />
            <br />
          </div>
        )}
        {/* // End hacky table */}
      </div>
    </>
  );
};

PropertyInfo.propTypes = {};

export default PropertyInfo;

import propTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { Actions } from "views/web/Property/constants";
import Typography from "../Typography/Typography";
import styles from "./LongToolTip.module.css";

const LongTooltip = (props) => {
  const { actionRequired, values } = props;
  const MANDATORY = "Mandatory HMO";
  const LANDLORD_REGISTER = "Landlord Register";
  const LANDLORD_LICENSING = "Landlord Licensing";
  const ADDITIONAL = "Additional HMO (254)";
  const SHORT_TERM_LET_LICENSING = "Short-term Let Licensing";
  const S257 = "Additional HMO (257)";
  const HMO_LICENSING = "HMO Licensing";
  const SUI_GENERIS = "Sui Generis";
  const ARTICLE_4 = "Article 4 HMO";
  const SHORT_TERM_LET_PLANNING = "Short-term Let Planning";
  const SECOND_HOME_PLANNING = "Second Home Planning";
  const MANDATORY_HMO = "Mandatory HMO";
  const AGENT_LICENSING = "Agent Licensing";
  const ADDITIONALS254 = "Additional S254";
  const ADDITIONALHMO = "Additional HMO";
  const ADDITIONAL254 = "Additional HMO (254)";
  const ADDITIONAL257 = "Additional HMO (257)";
  const SELECTIVE = "Selective";
  const ADDITIONALS257 = "Additional S257";
  const ARTICLE_4_HMO = "Article 4 HMO";
  const HMO_PLANNING = "HMO Planning";
  const SHORTLET_PLANNING = "Short-term Let Planning";
  const SHORTLET_LICENSING = "Short-term Let Licensing";
  const SECONDHOME_PLANNING = "Second Home Planning";
  const licensesArr = [
    MANDATORY,
    MANDATORY_HMO,
    LANDLORD_REGISTER,
    LANDLORD_LICENSING,
    ADDITIONAL,
    ADDITIONALHMO,
    ADDITIONALS254,
    ADDITIONALS257,
    ADDITIONAL254,
    ADDITIONAL257,
    SELECTIVE,
    S257,
    AGENT_LICENSING,
    SHORT_TERM_LET_LICENSING,
    HMO_LICENSING,
    HMO_LICENSING,
    SHORTLET_LICENSING,
    AGENT_LICENSING,
    LANDLORD_LICENSING,
  ];
  const planningArr = [
    SUI_GENERIS,
    ARTICLE_4,
    SHORT_TERM_LET_PLANNING,
    SECOND_HOME_PLANNING,
    ARTICLE_4_HMO,
    HMO_PLANNING,
    SHORTLET_PLANNING,
    SECONDHOME_PLANNING,
  ];
  const getLabelStyling = (labelType) => {
    const assocActions = actionRequired.filter((a) => {
      return licensesArr.includes(labelType)
        ? (a.value === Actions.LicenceRequired ||
            a.value === Actions.LicenceExpired ||
            a.value === Actions.LicenceExpiring) &&
            a.licences &&
            a.licences.some((l) => labelType.includes(l.label))
        : (a.value === Actions.PlanningRequired ||
            a.value === Actions.PlanningExpired) &&
            a.planning &&
            a.planning.some((p) => p.label === labelType);
    });

    if (assocActions.length > 0) {
      if (assocActions.some((a) => a.warning === "red")) return styles.red;
      else if (assocActions.some((a) => a.warning === "yellow"))
        return styles.yellow;
    }
  };

  const getDataTip = (value) => {
    switch (value) {
      case S257:
        return "Additional S257";
      case ADDITIONAL:
        return "Additional S254";
      default:
        return value;
    }
  };

  return (
    <div className={styles.container}>
      {values?.map((value, index) => (
        <span key={index} className={getLabelStyling(value)}>
          <a
            className={styles.toolTipContainer}
            data-tip={getDataTip(value)}
            data-event="mouseover click focus"
            data-event-off="mouseleave"
          >
            <Typography as="h3">
              {getDataTip(value)[0].toUpperCase()}
            </Typography>
          </a>
          <ReactTooltip
            className={styles.tooltip}
            place="bottom"
            type="dark"
            effect="solid"
          />
        </span>
      ))}
    </div>
  );
};

LongTooltip.propTypes = {
  value: propTypes.string,
};

export default LongTooltip;

import { ReactComponent as ActionRequired } from "images/actionRequired.svg";
import { ReactComponent as Wave } from "images/wave.svg";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as ActionRequiredYellow } from "images/actionRequiredYellow.svg";
import { ReactComponent as ActionRequiredRed } from "images/actionRequiredRed.svg";
import { ReactComponent as ActionRequiredArrow } from "images/actionRequiredArrow.svg";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { ReactComponent as FileRed } from "images/file_dock_red.svg";
import { ReactComponent as FileYellow } from "images/file_dock_yellow.svg";
import { ReactComponent as LocationPin } from "images/location-pin.svg";
import { Button } from "v2/components/Form";
import styles from "./StatusModal.module.css";
import Typography from "v2/components/Typography/Typography";
import { useHistory } from "react-router";
import { Warnings, Actions } from "./constants";
import { useCallback, useState } from "react";
import { formatter } from "yuno-formatting";

type Document = {
  label: string;
  expiryDates: string[];
};
type Licence = {
  label: string;
};
type Action = {
  action: string;
  value: string;
  expiryDate?: string;
  warning: string;
  url: string;
  documents?: Document[];
};

const StatusModal = ({ ...props }) => {
  const actionsRequired = props.actionRequired;
  const propertyUuid = props.property.uuid;
  const history = useHistory();

  const propertyURLBase = `/property/${propertyUuid}`;
  const nonCompliantText: string =
    "Actions required in the next few months, please update your documentation.";
  const compliantText: string =
    "No action required for this property as all documentation has been updated successfully";

  interface CompliantData {
    displayText: string;
  }

  const CompliantComponent = ({ displayText }: CompliantData) => {
    return (
      <div className={styles.logo}>
        <ActionRequired />
        <Typography as="h1">{capitalise(props.property.status)}</Typography>
        <span className={styles.subtitle}>
          <Typography as="h3">{displayText}</Typography>
        </span>
      </div>
    );
  };

  const capitalise = (input: string) => {
    if (!input) return "";
    const lower = input?.toLowerCase();
    const words = lower?.split(" ");
    let output = "";
    words.forEach(
      (word) => (output += word.charAt(0).toUpperCase() + word.slice(1) + " ")
    );
    return output;
  };

  const isExpired = (date: string) => {
    const today = new Date(Date.now());
    const inDate = new Date(date);

    const isExpired = new Date(inDate);
    isExpired.setDate(inDate.getDate());

    return isExpired < today;
  };

  const displayDate = (
    isRed: boolean,
    isDocuments: boolean,
    action: Action,
    index: number = 0
  ) => {
    let dateString = isRed ? "Expired: " : "Expires: ";
    dateString += isDocuments
      ? new Date(action.documents![index].expiryDates[0]).toLocaleDateString()
      : new Date(action.expiryDate!).toLocaleDateString();
    return dateString;
  };

  const DocumentsComponent = (props: any) => {
    const [docExpanded, setDocExpanded] = useState(true);
    const [licenceExpanded, setLicenceExpanded] = useState(true);

    const isRed = props.action.warning == Warnings.Red;
    const urlToAction = propertyURLBase + props.action.url;
    const isDocuments =
      props.action.value === Actions.DocumentsExpired ||
      props.action.value === Actions.DocumentsExpiring;
    const isLicences = props.action.value === Actions.LicenceRequired;


    if (props.action.value === Actions.LicenceExpiring)
      if (isExpired(props.action.expiryDate!)) return null;
    if (props.action.value === Actions.DocumentsExpiring) {
      props.action.documents = props.action.documents.filter((d: Document) =>
        d.expiryDates.some((e: string) => !isExpired(e))
      );
    }
    if (isDocuments) if (props.action?.documents?.length < 1) return null;

    return (
      <div className={styles.box} key={props.index}>
        <div
          className={styles.boxMain}
          onClick={() =>
            isDocuments || isLicences
              ? isLicences
                ? setLicenceExpanded(!licenceExpanded)
                : setDocExpanded(!docExpanded)
              : (history.push(urlToAction), props.onClose())
          }
        >
          <span className={styles.actionRequiredTitle}>
            <div className={styles.actionLogo}>
              {isRed ? <ActionRequiredRed /> : <ActionRequiredYellow />}
            </div>
            {capitalise(props.action.action)}
          </span>

          <span className={styles.expires}>
            {props.action.hasOwnProperty("expiryDate") &&
              displayDate(isRed, isDocuments, props.action)}
          </span>

          {isDocuments ? (
            <ArrowDown id={docExpanded ? "arrow-up" : "arrow"} />
          ) : isLicences ? (
            <ArrowDown id={licenceExpanded ? "arrow-up" : "arrow"} />
          ) : (
            <ActionRequiredArrow />
          )}
        </div>

        {/*If it has documents, append them through map*/}
        {props.action.hasOwnProperty("documents") &&
          docExpanded &&
          props.action!.documents!.map((document: Document, index: number) => {
            const expired = isExpired(document.expiryDates[0]);
            const actionTitle = props.action.action.toLowerCase();
            if (actionTitle === Actions.DocumentsExpiring && expired)
              return null;
            return (
              <div
                className={styles.docContainer}
                key={`Document: ${index}`}
                onClick={() => (history.push(urlToAction), props.onClose())}
              >
                <div className={styles.docDivider}></div>
                <div className={styles.document}>
                  <span className={styles.actionRequiredGrey}>
                    <div className={styles.actionLogo}>
                      {isRed ? <FileRed /> : <FileYellow />}
                    </div>
                    <div>{capitalise(document.label)}</div>
                  </span>

                  <span className={styles.expires}>
                    {displayDate(isRed, isDocuments, props.action, index)}
                  </span>
                  {isDocuments ? <ActionRequiredArrow /> : null}
                </div>
              </div>
            );
          })}

        {/*If it has licences, append them through map*/}
        {props.action.hasOwnProperty("licences") &&
          licenceExpanded &&
          props.action.value === Actions.LicenceRequired &&
          props.action?.licences?.map((licence: Licence, index: number) => {
            return (
              <div
                className={styles.licenceContainer}
                key={`Licence: ${index}`}
                onClick={() => (history.push(urlToAction), props.onClose())}
              >
                <div className={styles.docDivider}></div>
                <div className={styles.licence}>
                  <span className={styles.actionRequiredGrey}>
                    <div className={styles.actionLogo}>
                      {isRed ? <FileRed /> : <FileYellow />}
                    </div>
                    <div>{capitalise(licence.label)}</div>
                  </span>
                  {isLicences ? <ActionRequiredArrow /> : null}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderDocuments = useCallback(() => {
    return actionsRequired?.map((action: Action, index: number) => {
      // if (
      //   action.value === Actions.DocumentsExpired ||
      //   action.value === Actions.DocumentsExpiring
      // )
      return (
        <DocumentsComponent
          action={action}
          index={index}
          onClose={props.onClose}
        />
      );
    });
  }, [actionsRequired]);

  let compliantDisplayText = nonCompliantText;
  if (
    props.status.toLowerCase() == "compliant" ||
    props.status.toLowerCase() == "work in progress"
  ) {
    compliantDisplayText = compliantText;
  }
  const property = props.property;

  return (
    <div className={styles.container}>
      <CrossIcon
        className={styles.cross}
        onClick={props.onClose}
        onKeyDown={props.onClose}
      />

      <div className={styles.backgroundWave}>
        <Wave />
      </div>

      <div className={styles.content}>
        <CompliantComponent displayText={compliantDisplayText} />
      </div>

      <div className={styles.actions}>
        <div className={styles.address}>
          <LocationPin />
          {formatter.formatShortAddress(property)}
        </div>
        {renderDocuments()}
      </div>

      <footer>
        <Button actionName="Done" onClick={props.onClose} />
      </footer>
    </div>
  );
};

StatusModal.propTypes = {};
StatusModal.defaultProps = {};

export default StatusModal;
